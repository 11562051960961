/* === Import Firebase === */
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { PushNotifications } from "@capacitor/push-notifications";
import {
  getFirestore,
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  where,
  query,
  Timestamp,
  doc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import DeiterateCommon from "./DeiterateCommon";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

if (Capacitor.isNativePlatform()) {
  window.open = async (url) => Browser.open({ url });
}

/* === Initialise Firebase === */
// const window.deit.FBConfig = {
//   apiKey: import.meta.env.VITE_apiKey,
//   authDomain: import.meta.env.authDomain,
//   projectId: import.meta.env.projectId,
//   storageBucket: import.meta.env.storageBucket,
//   messagingSenderId: import.meta.env.messagingSenderId,
//   appId: import.meta.env.appId,
//   measurementId: import.meta.env.measurementId,
// };

// const deit.FBApp = initializeApp(window.deit.FBConfig);
// const deit.FBAuth = getAuth(deit.FBApp);
// const deit.FBDatabase = getFirestore(deit.FBApp);

/* === Import Framework7 === */
import $ from "dom7";
import Framework7 from "framework7/bundle";

/* === Import Framework7 Plugins === */
import F7WelcomescreenPlugin from "f7-welcomescreen";

// Import F7 Styles
import "framework7/css/bundle";

// Import Icons and App Custom Styles
import "../css/icons.css";
import "../css/app.scss";

// Import Routes
import routes from "./routes.js";
// Import Store
import store from "./store.js";

// Import main app component
import App from "../app.f7";
// import DeiterateCommon from './DeiterateCommon';
import Debugger from "./Debugger";

/* === Welcome Screen === */
var welcomescreen_slides = [
  {
    id: "slide0",
    title:
      '<img class="deiterate-logo" src="logos/deiterate-dark.svg" style="width: 200px" alt="de.iterate" /><p style="font-size: 18px; font-weight:550;">Dashboard</p>', // optional
    picture: `<div class="tutorialshot">
      <img src="tutorial/dashboard.png" style="margin-top: -5%; max-height: 50%;max-width: 50%;border: #00000061 solid 5px;border-radius: 9px;" alt="Screenshot 1" />
    </div>`,
    text: "Your de.iterate dashboard provides an overview of your key metrics. Click on any of the tiles to see more information.",
  },
  {
    id: "slide1",
    title:
      '<img class="deiterate-logo" src="logos/deiterate-dark.svg" style="width: 200px" alt="de.iterate" /><p style="font-size: 18px; font-weight:550;">Supplier Register</p>', // optional
    picture: `<div class="tutorialshot">
      <img src="tutorial/supplier-register.png" style="margin-top: -5%; max-height: 50%;max-width: 50%;border: #00000061 solid 5px;border-radius: 9px;" alt="Supplier Register" />
    </div>`,
    text: "View, Document and Review your Suppliers and their Data Storage activities in one simple and easy to use location.",
  },
  {
    id: "slide2",
    title:
      '<img class="deiterate-logo" src="logos/deiterate-dark.svg" style="width: 200px" alt="de.iterate" /><p style="font-size: 18px; font-weight:550;">Data Types</p>', // optional
    picture: `<div class="tutorialshot">
      <img src="tutorial/data-types.png" style="margin-top: -5%; max-height: 50%;max-width: 50%;border: #00000061 solid 5px;border-radius: 9px;" alt="Data Types" />
    </div>`,
    text: "Document where data is stored, how long it’s stored for and why you’re keeping it in your Data register.",
  },
  {
    id: "slide3",
    title:
      '<img class="deiterate-logo" src="logos/deiterate-dark.svg" style="width: 200px" alt="de.iterate" /><p style="font-size: 18px; font-weight:550;">Compliance Calendar</p>', // optional
    picture: `<div class="tutorialshot">
      <img src="tutorial/compliance-calendar.png" style="margin-top: -5%; max-height: 50%;max-width: 50%;border: #00000061 solid 5px;border-radius: 9px;" alt="Compliance Calendar" />
    </div>`,
    text: "See and perform your compliance tasks via our handy, prescheduled compliance assurance calendar.",
  },
  {
    id: "slide4",
    title:
      '<img class="deiterate-logo" src="logos/deiterate-dark.svg" style="width: 200px" alt="de.iterate" /><p style="font-size: 18px; font-weight:550;">Perform Task</p>', // optional
    picture: `<div class="tutorialshot">
      <img src="tutorial/perform-task.png" style="margin-top: -5%; max-height: 50%;max-width: 50%;border: #00000061 solid 5px;border-radius: 9px;" alt="Perform Task" />
    </div>`,
    text: 'Use the ‘Perform Task’ button inside Compliance  Calendar to capture evidence and perform your compliance tasks easily.<br><br><button class="button button-fancy" onclick="window.f7.welcomescreen.close();" href="#">Continue to de.iterate</button>',
  },
];

Framework7.use(F7WelcomescreenPlugin);

// Define options for welcomescreen plugin
var options = {
  bgcolor: "#dc9b1a",
  fontcolor: "#fff",
  open: false,
  navigation: true,
  pagination: true,
  onClosed: function () {
    console.log("Welcome screen closed");
    setDoc(
      doc(window.deit.FBDatabase, "users", window.deit.FBAuth.currentUser.uid),
      {
        welcomeOverlayCompleted: true,
      },
      { merge: true }
    ).then(() => {
      console.log("Welcome overlay completed");
    });
  },
};

/* === Initialise Framework7 === */
const DIApp = new Framework7({
  name: "de.iterate", // App name
  theme: "ios", // Automatic theme detection
  darkMode: "auto", // Auto dark mode
  colors: {
    primary: "#F1C89C",
  },
  statusbar: {
    iosOverlaysWebView: true,
  },
  el: "#app", // App root element
  component: App, // App main component

  // App store
  store: store,
  // App routes
  routes: routes,
  view: {
    browserHistory: true,
  },

  on: {
    pageAfterIn: function (page) {
      page.$el.css("overflow", "hidden");
      setTimeout(() => {
        page.$el.css("overflow", "");
      }, 0);
    },
  },

  welcomescreen: {
    // Setup welcomescreen plugin
    slides: welcomescreen_slides,
    options: options,
  },

  // Register service worker (only on production build)
  serviceWorker:
    process.env.NODE_ENV === "production"
      ? {
          path: "/service-worker.js",
        }
      : {},
});
console.log("Current mode:", import.meta.env.MODE);
console.log("Custom variable:", import.meta.env);

window.f7 = DIApp;
window.deit = new DeiterateCommon();
window.getCoordinates = window.deit.getCoordinates;
window.enc2html = window.deit.enc2html;
window.isActiveByBrackets = window.deit.isActiveByBrackets;
window.syntaxHighlight = window.deit.syntaxHighlight;

Object.defineProperty(String.prototype, "capitalise", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

// window.deit.applyPersonalSettings();

const getNodeIndex = (elm) => [...elm.parentNode.children].indexOf(elm);
window.getNodeIndex = getNodeIndex;
window.numToWords = window.deit.numToWords;
window.checkCorrectAnswer = window.deit.checkCorrectAnswer;

// window.deit.togglePreferredColours();

window.changePage = function (path, params = {}) {
  return window.f7.views.main.router.navigate(path, params);
};

window.timestamp = Timestamp;

PushNotifications.requestPermissions().then((result) => {
  if (result.receive === "granted") {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();
  } else {
    console.log("Push notifications permission denied");
    console.log(res);
  }
});

PushNotifications.addListener("registration", (token) => {
  console.log("Push registration success, token: " + token.value);
});

PushNotifications.addListener("registrationError", (error) => {
  console.log("Error on registration: " + JSON.stringify(error));
});

PushNotifications.addListener("pushNotificationReceived", (notification) => {
  console.log("Push received: " + JSON.stringify(notification));
});

PushNotifications.addListener(
  "pushNotificationActionPerformed",
  (notification) => {
    console.log("Push action performed: " + JSON.stringify(notification));
  }
);

onAuthStateChanged(window.deit.FBAuth, (user) => {
  if (user) {
    getDoc(
      doc(window.deit.FBDatabase, "users", window.deit.FBAuth.currentUser.uid)
    ).then((docSnap) => {
      console.log("User", user);
      // if (['SkeNW6MAwyPL3kYHljtfr3R6HRv1', 'DKrjIj3OgGVqh9LQywm4ysZCC9w1'].includes(user.uid)) {
      //   window.f7.$('html').addClass('experimental-ui');
      // } else {
      //   console.log('Not an experimental user');
      // }

      window.deit.getUserData().then((userDetails) => {
        console.log("User Details:", userDetails);
        userDetails = userDetails.data;
      });
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log(data);
        if (
          data.hasOwnProperty("welcomeOverlayCompleted") &&
          data.welcomeOverlayCompleted
        ) {
          console.log("Welcome overlay completed");
          window.f7.welcomescreen.close();
        } else {
          console.log("Welcome overlay not completed");
          if (
            JSON.parse(localStorage.getItem("planDetails"))["modules"].includes(
              "pa"
            )
          ) {
            window.f7.welcomescreen.open();
          }
        }
      }
    });
  }
});

String.prototype.replaceAllWithArray = function (find, replace) {
  if (Array.isArray(find) && Array.isArray(replace)) {
    const searchPattern = new RegExp(
      find.map((val) => "\\" + val).join("|"),
      "g"
    );
    return this.replace(searchPattern, replace[0]);
  } else {
    return this.replaceAll(find, replace);
  }
};
