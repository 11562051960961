const Debugger = function(gState, Method) {
  
    this.debug = {}
  
    if (gState && Method.isDebug) {
      for (var m in console)
        if (typeof console[m] == 'function')
          this.debug[m] = console[m].bind(window.console, `%c[DEBUG] %c(${Method.constructor.name})\n`, "color: #00f; font-weight: bold;", "color: #000; font-weight: bold;")
    } else {
      for (var m in console)
        if (typeof console[m] == 'function')
          this.debug[m] = function(){}
    }
    return this.debug
}

export default Debugger